.no-background-button {
  background-color: transparent;
  border: none;
  box-shadow: none;
  font-size: 16px;
}

.no-background-button:hover {
  background-color: transparent;

}

.ranking-modal {
  width: 800px !important;
  overflow: auto !important;
  box-shadow: none;
}

.modal-title {
  font-size: 16px;
  color: @primary-color;
}

.ranking-mask {
  background-color: 'rgba(162, 167, 176, 0.1)'
}

.others-tooltip {
  padding: 0px 20px;
  bottom: 0;
  background-color: white;
  width: 300px;
}

.others-tooltip .tooltip-text {
  font-size: 12px;
  border-radius: 6px;
  background-color: white;  
}

.sidebar-tooltip {
  max-width: 250px; 
}