.operations-page {
  span, p {
      font-family: 'MarkPro';
      &.float {
        float: 'right';
      }
      &.bold {
      font-family: 'MarkProBold';
      }
  }

  .custom-right {
    padding-right: 30px; 
  }

  .span-small {
    font-size: 11px;
  }

  .span-medium {
    font-size: 16px;
  }

  .span-red {
    color: #D02A2A;
  }

  .cmt-table-list {
    .ant-table-thead {
      .ant-table-cell {
        padding: 12px 7px;
      }
    }
  }

}