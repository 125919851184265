@import '~antd/lib/style/themes/default.less';
@import '~antd/dist/antd.less';
@import "~cmt-ui-kit/src/importsCRA.less";
@import './styles/callCenter.less';
@import './styles/schedulePage.less';
@import './styles/operationsPage.less';
@import './styles/commercial.less';
@import './styles/qualityPage.less';

body {
  margin: 0;
  padding: 0;
  font-family: 'MarkPro' !important;
  font-weight: normal;
  font-style: normal;
  background-color: @white;
}

@media only screen and (max-width: 480px) {
  main {
    padding: 0px 0px !important;
  }

  div.ant-picker {
    width: 100% !important;
  }
}

.question-circle {
  color: #223A70;
  margin-left: 3px;
}

.page-header {
  background-color: white;
}