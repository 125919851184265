.quality-page {
  span, p {
    font-family: 'MarkPro';
    &.float {
      float: 'right';
    }
    &.bold {
      font-family: 'MarkProBold';
    }
  }

  .custom-right {
    padding-right: 30px; 
  }

  .span-small {
    font-size: 11px;
  }

  .span-medium {
    font-size: 16px;
  }

}