.schedule-page {
  h2 {
    margin-bottom: 30px;
  }

  p {
    &.bold {
      font-family: 'MarkProBold';
    }
  }

  .chart-container {
    @media screen and (min-width: 482px) {
      width: 80% !important;
    }
    @media screen and (max-width: 481px) {
      width: 100% !important;
    }
  }
}